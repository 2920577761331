export default [
  //Admin
  {
    path: "/admin",
    name: "admin",
    meta: {
      title: "Administrator",
    },
    component: () => import("@/views/company-house/administrator/List.vue"),
  },
  {
    path: "/admin/add",
    name: "admin-add",
    meta: {
      title: "Create Administartor",
      action: "store",
    },
    component: () => import("@/views/company-house/administrator/Form.vue"),
  },
  // {
  //   path: "/admin/:id/show",
  //   name: "admin-show",
  //   component: () => import("@/views/company-house/administrator/View.vue"),
  // },
  {
    path: "/admin/:id",
    name: "admin-edit",
    meta: {
      title: "Update Adminsitrator",
      action: "edit",
    },
    component: () => import("@/views/company-house/administrator/Form.vue"),
  },

  // Users
  {
    path: "/user",
    name: "user",
    meta: {
      title: "User",
    },
    component: () => import("@/views/company-house/user/List.vue"),
  },
  {
    path: "/user/add",
    name: "user-add",
    meta: {
      title: "Create User",
      action: "store",
    },
    component: () => import("@/views/company-house/user/Form.vue"),
  },
  {
    path: "/user/:id/show",
    name: "user-show",
    meta: {
      title: "Detail User",
      action: "store",
    },
    component: () => import("@/views/company-house/user/Show.vue"),
  },
  {
    path: "/user/:id",
    name: "user-edit",
    meta: {
      title: "Update User",
      action: "edit",
    },
    component: () => import("@/views/company-house/user/Form.vue"),
  },

  // Contributor
  {
    path: "/contributor",
    name: "contributor",
    meta: {
      title: "Contribution",
    },
    component: () => import("@/views/company-house/contributor/List.vue"),
  },
  {
    path: "/contributor/add",
    name: "contributor-add",
    meta: {
      title: "Create Contribution",
      action: "store",
    },
    component: () => import("@/views/company-house/contributor/Form.vue"),
  },
  {
    path: "/contributor/:id/show",
    name: "contributor-show",
    meta: {
      title: "Detail Contribution",
      action: "store",
    },
    component: () => import("@/views/company-house/contributor/Show.vue"),
  },
  // {
  //   path: "/contributor/:id",
  //   name: "contributor-edit",
  //   meta: {
  //     title: "Update Contributor",
  //     action: "edit",
  //   },
  //   component: () => import("@/views/company-house/contributor/Form.vue"),
  // },

  // Role
  {
    path: "/role",
    name: "role",
    meta: {
      title: "Role",
    },
    component: () => import("@/views/company-house/role/List.vue"),
  },
  {
    path: "/role/add",
    name: "role-add",
    meta: {
      title: "Create Role",
      action: "store",
    },
    component: () => import("@/views/company-house/role/Form.vue"),
  },
  // {
  //   path: "/admin/:id/show",
  //   name: "admin-show",
  //   component: () => import("@/views/company-house/administrator/View.vue"),
  // },
  {
    path: "/role/:id",
    name: "role-edit",
    meta: {
      title: "Update Role",
      action: "edit",
    },
    component: () => import("@/views/company-house/role/Form.vue"),
  },

  // Management Language
  {
    path: "/language",
    name: "language",
    meta: {
      title: "Management Language",
    },
    component: () => import("@/views/company-house/language/List.vue"),
  },
  {
    path: "/language/add",
    name: "language-add",
    meta: {
      title: "Create Language",
      action: "store",
    },
    component: () => import("@/views/company-house/language/Form.vue"),
  },
  {
    path: "/language/:id",
    name: "language-edit",
    meta: {
      title: "Update Language",
      action: "edit",
    },
    component: () => import("@/views/company-house/language/Form.vue"),
  },

  // Company
  {
    path: "/company",
    name: "company",
    meta: {
      title: "Company",
    },
    component: () => import("@/views/company-house/company/List.vue"),
  },
  {
    path: "/company/add",
    name: "company-add",
    meta: {
      title: "Create Company",
      action: "store",
    },
    component: () => import("@/views/company-house/company/Form.vue"),
  },
  {
    path: "/company/:id/show",
    name: "company-show",
    meta: {
      title: "Detail Company",
      action: "store",
    },
    component: () => import("@/views/company-house/company/Show.vue"),
  },

  {
    path: "/company/:id",
    name: "company-edit",
    meta: {
      title: "Update Company",
      action: "edit",
    },
    component: () => import("@/views/company-house/company/Form.vue"),
  },

  // Content Intelektual
  {
    path: "/content-intelektual",
    name: "content-intelektual",
    meta: {
      title: "Management Content Intelektual",
    },
    component: () =>
      import("@/views/company-house/content-intelektual/List.vue"),
  },
  {
    path: "/content-intelektual/add",
    name: "content-intelektual-add",
    meta: {
      title: "Create Content Intelektual",
      action: "store",
    },
    component: () =>
      import("@/views/company-house/content-intelektual/Form.vue"),
  },
  {
    path: "/content-intelektual/:id",
    name: "content-intelektual-edit",
    meta: {
      title: "Update Content Intelektual",
      action: "edit",
    },
    component: () =>
      import("@/views/company-house/content-intelektual/Form.vue"),
  },

  // Content Company
  {
    path: "/content-company",
    name: "content-company",
    meta: {
      title: "Management Content Company",
    },
    component: () => import("@/views/company-house/content-company/List.vue"),
  },
  {
    path: "/content-company/add",
    name: "content-company-add",
    meta: {
      title: "Create Content Company",
      action: "store",
    },
    component: () => import("@/views/company-house/content-company/Form.vue"),
  },
  {
    path: "/content-company/:id",
    name: "content-company-edit",
    meta: {
      title: "Update Content Company",
      action: "edit",
    },
    component: () => import("@/views/company-house/content-company/Form.vue"),
  },

  // How We Work
  {
    path: "/how-we",
    name: "how-we",
    meta: {
      title: "Management Content How We Work",
    },
    component: () => import("@/views/company-house/content-how-we/List.vue"),
  },
  {
    path: "/how-we/add",
    name: "how-we-add",
    meta: {
      title: "Create Content How We Work",
      action: "store",
    },
    component: () => import("@/views/company-house/content-how-we/Form.vue"),
  },
  {
    path: "/how-we/:id/show",
    name: "how-we-show",
    meta: {
      title: "Detail Content How We Work",
      action: "store",
    },
    component: () => import("@/views/company-house/content-how-we/Show.vue"),
  },
  {
    path: "/how-we/:id",
    name: "how-we-edit",
    meta: {
      title: "Update Content How We Work",
      action: "edit",
    },
    component: () => import("@/views/company-house/content-how-we/Form.vue"),
  },

  // Content Contribute
  {
    path: "/content-contribute",
    name: "content-contribute",
    meta: {
      title: "Management Content Contribution",
    },
    component: () =>
      import("@/views/company-house/content-contribute/List.vue"),
  },
  {
    path: "/content-contribute/:id/show",
    name: "content-contribute-show",
    meta: {
      title: "Detail Content Contribute",
      action: "store",
    },
    component: () =>
      import("@/views/company-house/content-contribute/Show.vue"),
  },
  {
    path: "/content-contribute/add",
    name: "content-contribute-add",
    meta: {
      title: "Create Content Contribute",
      action: "store",
    },
    component: () =>
      import("@/views/company-house/content-contribute/Form.vue"),
  },
  {
    path: "/content-contribute/:id",
    name: "content-contribute-edit",
    meta: {
      title: "Update Content Contribute",
      action: "edit",
    },
    component: () =>
      import("@/views/company-house/content-contribute/Form.vue"),
  },

  // Content About Us
  {
    path: "/content-about-us",
    name: "content-about-us",
    meta: {
      title: "Management Content About Us",
    },
    component: () => import("@/views/company-house/content-about-us/List.vue"),
  },
  {
    path: "/content-about-us/add",
    name: "content-about-us-add",
    meta: {
      title: "Create Content About Us",
      action: "store",
    },
    component: () => import("@/views/company-house/content-about-us/Form.vue"),
  },
  {
    path: "/content-about-us/:id",
    name: "content-about-us-edit",
    meta: {
      title: "Update Content About Us",
      action: "edit",
    },
    component: () => import("@/views/company-house/content-about-us/Form.vue"),
  },
  {
    path: "/content-about-us/:id/show",
    name: "content-about-us-show",
    meta: {
      title: "Detail Content About Us",
      action: "store",
    },
    component: () => import("@/views/company-house/content-about-us/Show.vue"),
  },

  // Privacy Policy
  // Content About Us
  {
    path: "/content-privacy",
    name: "content-privacy",
    meta: {
      title: "Management Content Privacy Policy",
    },
    component: () => import("@/views/company-house/content-privacy/List.vue"),
  },
  {
    path: "/content-privacy/add",
    name: "content-privacy-add",
    meta: {
      title: "Create Content Privacy Policy",
      action: "store",
    },
    component: () => import("@/views/company-house/content-privacy/Form.vue"),
  },
  {
    path: "/content-privacy/:id",
    name: "content-privacy-edit",
    meta: {
      title: "Update Content Privacy Policy",
      action: "edit",
    },
    component: () => import("@/views/company-house/content-privacy/Form.vue"),
  },
  {
    path: "/content-privacy/:id/show",
    name: "content-privacy-show",
    meta: {
      title: "Detail Content Privacy Policy",
      action: "store",
    },
    component: () => import("@/views/company-house/content-privacy/Show.vue"),
  },
];
